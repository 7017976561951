import {ECOM_MEMBERS_AREA_DEFAULT_PAGES, FedopsInteractions} from '../constants';
import {
  addApplications,
  installMembersArea,
  registerMembersAreaApps,
  isMembersAreaInstalled,
} from '@wix/members-area-integration-kit';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponentsData, EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {getEcomPagesConfiguration} from '../../common/appManifest/appManifest';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {StyleParams} from '../services/StyleParams';
import {EditorSDK, PageRef, RouterRef} from '@wix/platform-editor-sdk';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';
import {ExperimentsApi} from '../../common/experiments/ExperimentsApi';
import {createWishlistApi, InstallationOrigin} from './wishlist/wishlistActions';
import {DependantApps} from '../services/DependantApps';
import {translateFunctionFactory} from '../../common/translations/translations';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {WIX_ECOM, WIX_NEW_STORES} from '@wix/app-definition-ids';
import {getEcomPages} from '../../common/pages';
import {AnyFunction} from '@wix/wixstores-client-core';

export async function addPage(
  sdk: EditorSDK,
  pageId: string,
  managingAppDefId: string,
  shouldNavigate: boolean,
  fedopsLogger: Record<string, any>,
  reportError: AnyFunction
) {
  try {
    managingAppDefId = pageId === PageMap.CHECKOUT ? WIX_ECOM : managingAppDefId;

    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddPage);
    let pageData: {pageRef: PageRef};
    if (pageId === PageMap.SIDE_CART) {
      pageData = {pageRef: await addSideCart(sdk, managingAppDefId)};
    } else {
      pageData = (await sdk.tpa.add.component('', {
        appDefinitionId: WIX_ECOM,
        managingAppDefId,
        componentType: sdk.document.tpa.TPAComponentType.Page,
        page: {
          pageId,
          shouldNavigate,
        },
      })) as {
        compId: string;
        pageRef: PageRef;
        pageUriSEO: string;
        title: string;
      };
    }

    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddPage);

    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState);
    await sdk.document.pages.setState('', {
      state: {
        [pageId]: [{id: pageData.pageRef.id}],
      },
    });
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddPageSetState);

    return pageData.pageRef;
  } catch (e) {
    reportError(e);
  }
}

export function addWidget(
  sdk: EditorSDK,
  widgetId: string,
  addToAllPages: boolean,
  fedopsLogger: Record<string, any>,
  reportError: AnyFunction
) {
  try {
    fedopsLogger?.interactionStarted?.(FedopsInteractions.EcomPlatformPublicApiAddWidget);
    const compData = sdk.tpa.add.component('', {
      appDefinitionId: WIX_ECOM,
      componentType: sdk.tpa.TPAComponentType.Widget,
      widget: {
        widgetId,
        allPages: addToAllPages,
      },
    });
    fedopsLogger?.interactionEnded?.(FedopsInteractions.EcomPlatformPublicApiAddWidget);

    return compData;
  } catch (e) {
    reportError(e);
  }
}

export function getRouterRefByPrefix(sdk: EditorSDK, prefix: string) {
  return sdk.routers.getByPrefix('', {prefix});
}

export async function addRouter(sdk: EditorSDK, prefix: string, reportError: AnyFunction) {
  try {
    const routerRef = await getRouterRefByPrefix(sdk, prefix);
    if (routerRef) {
      return routerRef;
    }

    return await sdk.routers.add('', {prefix});
  } catch (e) {
    reportError(e);
  }
}

export function getRouter(sdk: EditorSDK, routerRef: RouterRef) {
  return sdk.routers.get('', {routerRef});
}

export function connectPageToRouter(
  sdk: EditorSDK,
  {pageRef, routerRef, pageRoles}: {pageRef: PageRef; routerRef: RouterRef; pageRoles: string[]},
  reportError: AnyFunction
) {
  return sdk.routers.pages
    .connect('', {
      pageRef,
      routerRef,
      pageRoles,
    })
    .catch((e) => {
      reportError(e);
    });
}

export const toMonitored = async <T>(
  fedopsLogger: Record<string, any>,
  interactionName: string,
  action: () => Promise<T> | T
): Promise<T> => {
  try {
    fedopsLogger?.interactionStarted?.(interactionName);
    const response = await action();
    fedopsLogger?.interactionEnded?.(interactionName);
    return response;
  } catch (err) {
    fedopsLogger?.interactionFailed?.(interactionName, err as Error);
    throw err;
  }
};

export const addSideCart = async (sdk: EditorSDK, managingAppDefId: string) => {
  const CART_WIDGET_ID = '49dbb2d9-d9e5-4605-a147-e926605bf164';
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;

  const componentDefinition = {
    type: 'Component',
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: {
      width: 494,
      height: 815,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    data: {
      type: 'TPAMultiSection',
      applicationId: tpaApplicationId.toString(),
      appDefinitionId: WIX_ECOM,
      widgetId: CART_WIDGET_ID,
    },
  };
  return sdk.pages.popupPages.add('', {
    title: 'Side Cart',
    definition: {
      data: {
        tpaApplicationId,
        tpaPageId: PageMap.SIDE_CART,
        managingAppDefId,
        appDefinitionId: WIX_ECOM,
        pageBackgrounds: {
          desktop: {
            custom: true,
            ref: {
              type: 'BackgroundMedia',
              color: '#A5C8DE',
              colorOpacity: 0.75,
              alignType: 'top',
              fittingType: 'fill',
              scrollType: 'fixed',
            },
            isPreset: true,
          },
          mobile: {
            custom: true,
            ref: {
              type: 'BackgroundMedia',
              color: '#A5C8DE',
              colorOpacity: 0.75,
              alignType: 'top',
              fittingType: 'fill',
              scrollType: 'fixed',
            },
            isPreset: true,
          },
        },
      },
      components: [
        {
          type: 'Container',
          components: [componentDefinition],
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            width: 494,
            height: 815,
            x: 682,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: {
            type: 'PopupContainerProperties',
            alignmentType: 'fullHeight',
            horizontalAlignment: 'right',
            verticalAlignment: 'top',
            horizontalOffset: 0,
            verticalOffset: 0,
          },
        },
      ],
    },
  } as any);
};

export async function addMembersArea(
  editorType: string,
  firstInstall: boolean,
  biData?: {origin?: string},
  siteCreationFlow?: boolean,
  fedopsLogger?: Record<string, any>
) {
  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaRegister, () =>
    registerMembersAreaApps(ECOM_MEMBERS_AREA_DEFAULT_PAGES)
  );

  if (!firstInstall || editorType === 'ADI' || siteCreationFlow) {
    return Promise.resolve();
  }

  const options = biData && {biData};
  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaInstallation, () => installMembersArea(options));

  await toMonitored(fedopsLogger, FedopsInteractions.MemberAreaAddApplication, () =>
    addApplications(ECOM_MEMBERS_AREA_DEFAULT_PAGES, options)
  );
}

export async function setStateForStoresPages(sdk: EditorSDK) {
  const allSitePages = await sdk.pages.data.getAll('');
  const storesPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === WIX_NEW_STORES);
  return Promise.all(
    storesPages.map((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CATEGORY ||
        pageData.tpaPageId?.startsWith(PageMap.GALLERY)
      ) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export async function setStateForPages(sdk: EditorSDK) {
  const ecomPages = await getEcomPages(sdk);
  return Promise.all(
    ecomPages.map((pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        return sdk.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
      return Promise.resolve();
    })
  );
}

export function openEcomBillingPage(editorSDK: EditorSDK) {
  return editorSDK.editor.openBillingPage('', {premiumIntent: 'PAID', referrer: 'stores_app_product'});
}

export async function installWishlistApp({
  sdk,
  dependantApps,
  t,
  webBiLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  t: (keyToTranslate: string) => string;
  webBiLogger: WebBiLogger;
}) {
  const wishlistApi = createWishlistApi({sdk, dependantApps, t, webBiLogger});
  if (await isMembersAreaInstalled()) {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: false});
  } else {
    return wishlistApi.installWishlist({origin: InstallationOrigin.Plugins, installMembersArea: true});
  }
}

export function updateEcomPlatformInstallationsData(
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appDefId: string,
  ecomComponentsData: EcomComponentsData
) {
  ecomPlatformInstallationState.addInstallAppComponentsData(appDefId, ecomComponentsData);
}

export async function updateEcomPagesForPagesPanel(
  editorSDK: EditorSDK,
  ecomPlatformInstallationState: EcomPlatformInstallationState
) {
  const manageAppId = ecomPlatformInstallationState.getTheAppManageAppId();

  const tpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll('');
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);

  return Promise.all(
    ecomPages.map(async (pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        const pageRef: PageRef = {id: pageData.id, type: 'DESKTOP'};
        await editorSDK.pages.data.update('', {
          pageRef,
          data: {managingAppDefId: manageAppId},
        });
        return editorSDK.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    })
  );
}

export async function getEcomPlatformAppManifest(t, locale) {
  if (!t) {
    t = await translateFunctionFactory(locale);
  }
  const ecomPages = getEcomPagesConfiguration(t, getAppVersion(), locale);

  return {
    pages: {
      pageActions: {
        [PageMap.CART]: ecomPages[PageMap.CART].actions,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].actions,
      },
      pageSettings: {
        [PageMap.CART]: ecomPages[PageMap.CART].settings,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].settings,
      },
      pageDescriptors: {
        [PageMap.CART]: ecomPages[PageMap.CART].descriptors,
        [PageMap.THANKYOU]: ecomPages[PageMap.THANKYOU].descriptors,
      },
    },
  };
}

export async function setThankYouPageStyleParams({
  sdk,
  ecomPlatformInstallationState,
  styleParams,
}: {
  sdk: EditorSDK;
  ecomPlatformInstallationState: EcomPlatformInstallationState;
  styleParams?: EcomStyleParams;
  experimentsApi: ExperimentsApi;
}) {
  if (!ecomPlatformInstallationState.isMoreThanOneAppInstalled()) {
    const applicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
    const styleParamsHandler = new StyleParams(sdk, applicationId);
    return styleParamsHandler.setThankYouPageStyleParams(styleParams);
  }
}
